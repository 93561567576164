import { render, staticRenderFns } from "./manufacturschemeDetails.vue?vue&type=template&id=006335f2&scoped=true&"
import script from "./manufacturschemeDetails.vue?vue&type=script&lang=js&"
export * from "./manufacturschemeDetails.vue?vue&type=script&lang=js&"
import style0 from "./manufacturschemeDetails.vue?vue&type=style&index=0&id=006335f2&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "006335f2",
  null
  
)

export default component.exports